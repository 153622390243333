<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
  <v-card>
                <v-card-title
                  class="headline grey lighten-2"
                  primary-title
                >Subject - Person with Disability</v-card-title>

                <v-card-text>
                  <v-col cols="12">
                    <v-select
                      v-model="vm.title"
                      :items="title"
                      label="Title"
                      v-validate="'required'"
                      data-vv-as="Title"
                      name="title"
                      :error-messages="errors.collect('title')"
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="vm.firstName"
                      label="First name"
                      v-validate="'required'"
                      data-vv-as="First name"
                      name="firstName"
                      :error-messages="errors.collect('firstName')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="vm.lastName"
                      label="Last name"
                      v-validate="'required'"
                      data-vv-as="Last name"
                      name="lastName"
                      :error-messages="errors.collect('lastName')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="vm.ndisNumber"
                      label="NDIS participant number"
                      v-validate="'required'"
                      data-vv-as="NDIS participant number"
                      name="ndisNumber"
                      :error-messages="errors.collect('ndisNumber')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="vm.gender"
                      :items="gender"
                      label="Gender"
                      v-validate="'required'"
                      data-vv-as="gender"
                      name="gender"
                      :error-messages="errors.collect('gender')"
                    ></v-select>
                  </v-col>

                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        v-model="dobMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="vm.dateOfBirth"
                            label="Date of Birth"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="vm.dateOfBirth" @input="dobMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col
                      cols="4"
                      v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'"
                    >
                      <v-text-field
                        v-model="vm.ageAtTimeOfIncident"
                        label="Age at the time of incident"
                        v-validate="'required'"
                        data-vv-as="Age at the time of incident"
                        name="ageAtTimeOfIncident"
                        :error-messages="errors.collect('ageAtTimeOfIncident')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col cols="12">
                    <v-select
                      v-model="vm.primaryDisability"
                      :items="primaryDisability"
                      label="Primary Disability"
                      v-validate="'required'"
                      data-vv-as="primaryDisability"
                      name="primaryDisability"
                      :error-messages="errors.collect('primaryDisability')"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    v-if="vm.primaryDisability == 'Other' || vm.primaryDisability=='Other neurological' || vm.primaryDisability=='Other physical' || vm.primaryDisability =='Other sensory/speech'"
                  >
                    <v-text-field
                      v-model="vm.primaryDisabilityOther"
                      label="please specify other primary disability"
                      v-validate="'required'"
                      data-vv-as="please specify other primary disability"
                      name="primaryDisabilityOther"
                      :error-messages="errors.collect('primaryDisabilityOther')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="vm.secondaryDisability"
                      :items="secondaryDisability"
                      label="Secondary Disability"
                      v-validate="'required'"
                      data-vv-as="secondaryDisability"
                      name="secondaryDisability"
                      :error-messages="errors.collect('secondaryDisability')"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    v-if="vm.secondaryDisability == 'Other' || vm.secondaryDisability=='Other neurological' || vm.secondaryDisability=='Other physical' || vm.secondaryDisability =='Other sensory/speech'"
                  >
                    <v-text-field
                      v-model="vm.secondaryDisabilityOther"
                      label="please specify other secondary disability"
                      v-validate="'required'"
                      data-vv-as="please specify other secondary disability"
                      name="secondaryDisabilityOther"
                      :error-messages="errors.collect('secondaryDisabilityOther')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="vm.phoneNumber"
                      label="Phone number"
                      v-validate="'required'"
                      data-vv-as="Phone number"
                      name="phoneNumber"
                      :error-messages="errors.collect('phoneNumber')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="vm.email"
                      label="E-mail address"
                      v-validate="'required|email'"
                      data-vv-as="Email address"
                      name="email"
                      :error-messages="errors.collect('email')"
                    ></v-text-field>
                  </v-col>

                  <v-row
                    v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'"
                  >
                    <v-col cols="6">
                      <v-text-field
                        v-model="vm.Address"
                        label="Address"
                        v-validate="'required'"
                        data-vv-as="Address"
                        name="Address"
                        :error-messages="errors.collect('Address')"
                      ></v-text-field>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-select
                        v-model="vm.AddressType"
                        :items="AddressType"
                        label="Address Type"
                        v-validate="'required'"
                        data-vv-as="Address Type"
                        name="AddressType"
                        :error-messages="errors.collect('AddressType')"
                      ></v-select>
                    </v-col>
                    <v-col cols="3" v-if="vm.AddressType == 'Other'">
                      <v-text-field
                        v-model="vm.AddressTypeOther"
                        label="Address Type Other"
                        v-validate="'required'"
                        data-vv-as="Address Type Other"
                        name="AddressType"
                        :error-messages="errors.collect('AddressType')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        v-model="vm.timeAtAddress"
                        label="Time at Address"
                        v-validate="'required'"
                        data-vv-as="Time at address"
                        name="timeAtAddress"
                        :error-messages="errors.collect('timeAtAddress')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        v-model="vm.indigenousStatus"
                        :items="indigenousStatus"
                        label="Indigenous status"
                        v-validate="'required'"
                        data-vv-as="Indigenous status"
                        name="indigenousStatus"
                        :error-messages="errors.collect('indigenousStatus')"
                      ></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model="vm.languagePreference"
                        label="Language preference"
                        v-validate="'required'"
                        data-vv-as="Language preference"
                        name="languagePreference"
                        :error-messages="errors.collect('languagePreference')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-select
                        v-model="vm.countryOfBirth"
                        :items="countryOfBirth"
                        label="Country of birth"
                        v-validate="'required'"
                        data-vv-as="Country of birth"
                        name="countryOfBirth"
                        :error-messages="errors.collect('countryOfBirth')"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="this.currentIncident.incidentOverview.hasDeathOccuredOfAllegationOfPersonWithDisability == true"
                  >
                    <v-col cols="12">
                      <v-menu
                        v-model="dodMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="vm.deceasedDate"
                            label="deceasedDate"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="vm.deceasedDate" @input="dodMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
<v-row v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'">
                  <v-col cols="12">
                    <v-radio-group
                      v-model="vm.isGuardianAppointed"
                      label="Is a guardian appointed?"
                      v-validate="'required'"
                      data-vv-as="Is a guardian appointed"
                      name="isGuardianAppointed"
                      :error-messages="errors.collect('isGuardianAppointed')"
                    >
                      <v-radio label="Yes" v-bind:value="true"></v-radio>
                      <v-radio label="No" v-bind:value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
</v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="vm.behavioursOfConcern"
                      :items="behavioursOfConcern"
                      label="Behaviours of Concern"
                      v-validate="'required'"
                      data-vv-as="Behaviours of Concern"
                      name="behavioursOfConcern"
                      :error-messages="errors.collect('behavioursOfConcern')"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" v-if="vm.behavioursOfConcern == 'Other'">
                    <v-text-field
                      v-model="vm.behavioursOfConcernOther"
                      label="please specify other behaviours of concern"
                      v-validate="'required'"
                      data-vv-as="please specify other behaviours of concern"
                      name="behavioursOfConcernOther"
                      :error-messages="errors.collect('behavioursOfConcernOther')"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    v-if="this.currentIncident.incidentOverview.incidentNotificationType == '5 Day Notification'"
                  >
                    <v-radio-group
                      v-model="vm.requiresInformalDecisionMakingSupport"
                      label="Does the person require informal decision-making support?"
                      v-validate="'required'"
                      data-vv-as=" informal decision-making support"
                      name="requiresInformalDecisionMakingSupport"
                      :error-messages="errors.collect('requiresInformalDecisionMakingSupport')"
                    >
                      <v-radio label="Yes" v-bind:value="true"></v-radio>
                      <v-radio label="No" v-bind:value="false"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="vm.communication"
                      :items="communication"
                      label="How does the person communicate?"
                      v-validate="'required'"
                      data-vv-as="communication"
                      name="communication"
                      :error-messages="errors.collect('communication')"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4" v-if="vm.communication == 'Other'">
                    <v-text-field
                      v-model="vm.communicationOther"
                      label="please specify other behaviours of concern"
                      v-validate="'required'"
                      data-vv-as="please specify other behaviours of concern"
                      name="communicationOther"
                      :error-messages="errors.collect('communicationOther')"
                    ></v-text-field>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="close">Cancel</v-btn>
                  <v-btn color="primary" type="submit">Save</v-btn>
                </v-card-actions>
              </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
export default {
  name: "notes-form",

  data: () => ({
    vm: {},
    dobMenu: null,
    dodMenu: null,
    form: {},
    title: ["Mr", "Mrs", "Ms", "Miss", "Dr"],
    gender: ["Male", "Female", "Indeterminate", "Intersex", "Unspecified"],
      primaryDisability: [
      "Autism",
      "Intellectual disability",
      "Cerebral Palsy",
      "Psychosocial disability",
      "Other neurological",
      "Other physical",
      "Acquired brain injury",
      "Visual impairment",
      "Other sensory/speech",
      "Multiple sclerosis",
      "Stroke",
      "Spinal cord injury",
      "Other"
    ],
    secondaryDisability: [
      "Autism",
      "Intellectual disability",
      "Cerebral Palsy",
      "Psychosocial disability",
      "Other neurological",
      "Other physical",
      "Acquired brain injury",
      "Visual impairment",
      "Other sensory/speech",
      "Multiple sclerosis",
      "Stroke",
      "Spinal cord injury",
      "Other"
    ],
    AddressType: [
      "Own Home",
      "Family Home",
      "Renting",
      "Group home - SDA",
      "Group home - non-SDA",
      "Residential Facility",
      "Respite",
      "Temporary Care",
      "Other"
    ],
    behavioursOfConcern: [
      "Food-related",
      "Eating non-food items",
      "Propert damage",
      "Physical aggresion",
      "Verbal aggresion",
      "Harm to self",
      "Unintentional self-risk",
      "Leaving premises without support",
      "Refusal to do things",
      "Repetitive or unusual habits",
      "Offending behaviour",
      "Sexually inappropriate behaviour",
      "Other"
    ],
    communication: [
      "Verbal communication",
      "Adjusted verbal language",
      "Electronic communication",
      "Picture communication",
      "Sign language",
      "Other signing",
      "Use of gestures",
      "Interpreter",
      "Other"
    ],
    countryOfBirth: [
      "Australia",
      "New Zealand",
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Austria",
      "Azerbaijan",
      "The Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo, Democratic Republic of the",
      "Congo, Republic of the",
      "Costa Rica",
      "Côte d’Ivoire",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor (Timor-Leste)",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "The Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea, North",
      "Korea, South",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia, Federated States of",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (Burma)",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Sudan, South",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe"
    ],
    indigenousStatus: [
      "None",
      "Aboriginal",
      "Torres Strait Islander",
      "South Sea Islander Origin",
      "Does not want to give this information"
    ]
  }),
  watch: {
    subject(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  props: {
    subject: Object,
    currentIncident: Object
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      if (this.subject === undefined || this.subject === null) {
        this.vm = {};
      } else {
        this.vm = this.subject;
      }

      if (
        this.currentIncident.subjectsOfAllegationDisability.subjects === undefined ||
        this.currentIncident.subjectsOfAllegationDisability.subjects === null
      ) {
        this.currentIncident.subjectsOfAllegationDisability.subjects = [];
      }
    },

    close() {
      this.$emit("subject-closed");
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("subject-added", this.vm);
        }
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

